import React from 'react';
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import TemplateWrapper from '../Layout';

const ServicePage = ({ data: { prismicPackages } }) => {
  const { data } = prismicPackages;
  return (
    <TemplateWrapper>
      <main>
        <section className="section--packagedetails">
          <div className="wrapper">
            {data.name &&
              <h1 className="packagedetails__title">{data.name.text}</h1>
            }
            {data.price &&
              <div className="packagedetails__price">${data.price}</div>
            }
            {data.full_details &&
              <div dangerouslySetInnerHTML={{ __html: data.full_details.html }} className="packagedetails__html" />
            }
            {data.name &&
              <Link to="/get-started" state={{ serviceSelected: data.name.text }} className="cta cta--primary">Select Package</Link>
            }
          </div>
        </section>
        <section className="section-cta section-cta--home">
          <div className="wrapper">
            <h2>Want to keep looking at all our services?</h2>
            <Link to="/services" className="cta cta--ghost">See all packages</Link>
          </div>
        </section>
      </main>
    </TemplateWrapper>
  );
};

export const query = graphql`
  query ServiceBySlug($uid: String!) {
    prismicPackages(uid: { eq: $uid }) {
      uid
      data {
        name {
          text
        }
        price
        full_details {
          html
        }
      }
    }
  }
`;

ServicePage.propTypes = {
  data: PropTypes.object.isRequired
};

export default ServicePage;
